export const navElements = [
  {
    title: "Home",
    to: "home",
    id: 1,
  },
  {
    title: "Projects",
    to: "projects",
    id: 2,
  },
  {
    title: "Articles",
    to: "articles",
    id: 3,
  },
  {
    title: "Contact",
    to: "contact",
    id: 4,
  },
]
