export const theme = {
  colors: {
    primary: "#FEF7E5",
    secondary: "",
    tertialry: "",
    textColor: "#000000",
    successColor: "#66A15A",
    grayText: "#9A9A9A",
    darkGrayText: "#303030",
    darkText: "#1A1A1A",
    black: "#000000",
    white: "#ffffff",
    medium: "",
    light: "",
    dark: "",
    danger: "",
  },
  fonts: {
    poppins: "Poppins",
  },
  paddings: {
    large: "60px",
  },
  container: "",
  containerLarge: "",
}
