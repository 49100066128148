import React from "react"
import styled from "styled-components"
import { device } from "../../styles/BreakPoints"

const FooterStyle = styled.footer`
  background: transparent linear-gradient(271deg, #481a72 0%, #57298b 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 10px 24px #7e7e7e63;
  height: 82px;
  display: grid;
  place-items: center;
  @media ${device.mobileL} {
    margin-bottom: ${({ mb }) => (mb ? "50px" : "none")};
    height: 50px;
  }
`

const FooterText = styled.p`
  color: #ffffff;
  font-size: 16px;
  @media ${device.mobileL} {
    font-size: 14px;
  }
`

function Footer({ mb }) {
  return (
    <FooterStyle mb={mb}>
      <FooterText>&copy; Copyright 2022, ABDUL BASIT .Inc.</FooterText>
    </FooterStyle>
  )
}

export default Footer
