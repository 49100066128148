import styled, { createGlobalStyle, css } from "styled-components"
import { device } from "./BreakPoints"
import GilroyBold from "../assets/fonts/Gilroy-Bold.ttf"
import GilroyExtraBold from "../assets/fonts/Gilroy-ExtraBold.ttf"
import GilroyLight from "../assets/fonts/Gilroy-Light.ttf"
import GilroyMedium from "../assets/fonts/Gilroy-Medium.ttf"
import GilroyRegular from "../assets/fonts/Gilroy-Regular.ttf"
import GilroySemiBold from "../assets/fonts/Gilroy-SemiBold.ttf"
import GilroyThin from "../assets/fonts/Gilroy-Thin.ttf"
import GilroyUltraThin from "../assets/fonts/Gilroy-UltraLight.ttf"

export const GlobalStyles = createGlobalStyle`
  @font-face {
  font-family: "Gilroy";
  src: url(${GilroyBold}) format('truetype');
  font-weight: 700;
}
  @font-face {
  font-family: "Gilroy";
  src: url(${GilroyExtraBold}) format('truetype');
  font-weight: 800;
}
  @font-face {
  font-family: "Gilroy";
  src: url(${GilroyLight}) format('truetype');
  font-weight: 300;
}
  @font-face {
  font-family: "Gilroy";
  src: url(${GilroyMedium}) format('truetype');
  font-weight: 500;
}
  @font-face {
  font-family: "Gilroy";
  src: url(${GilroySemiBold}) format('truetype');
  font-weight: 600;
}
  @font-face {
  font-family: "Gilroy";
  src: url(${GilroyRegular}) format('truetype');
  font-weight: 400;
}
  @font-face {
  font-family: "Gilroy";
  src: url(${GilroyThin}) format('truetype');
  font-weight: 200;
}
  @font-face {
  font-family: "Gilroy";
  src: url(${GilroyUltraThin}) format('truetype');
  font-weight: 100;
}

 /* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    min-height: 100vh;
    text-rendering: optimizeSpeed;
    /* font-family: ${(props) => props.theme.fonts.poppins}, sans-serif; */
    font-family: 'Gilroy', sans-serif;
  } 
h1,
h2,
h3,
h4,
h5,
h6,
p,
figure,
blockquote,
ul,
li,
dl,
dd {
    padding: 0;
    margin: 0;
}
/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}
li {
  list-style-type: none;
}
/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}
/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}
/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
   scroll-behavior: auto;
  }
  
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
`

export const Container = styled.div`
  width: 100%;
  max-width: 1320px;
  margin: 0 auto;
  padding: 0 40px;
  @media ${device.laptop} {
    padding: 0 15px;
  }
`

export const ContainerLarge = styled.div`
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding: 0 60px;
  @media ${device.laptop} {
    padding: 0 15px;
  }
`

export const FlxBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const AppBorder = styled.div`
  border: 2px solid ${(props) => props.theme.colors.primary};
  border-radius: 5px;
`

export const mt24 = styled.div`
  margin-top: 24px;
  @media ${device.tablet} {
    margin-top: 13px;
  }
`

export const AbsCenter = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const SectionWrapper = css`
  padding-top: 50px;
  padding-bottom: 60px;
  @media ${device.laptopL} {
    padding-top: 30x;
    padding-bottom: 35px;
  }
  @media ${device.tablet} {
    padding-top: 20px;
    padding-bottom: 20px;
  }
`
