const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "600px",
  tablet: "768px",
  tabletL: "845px",
  laptop: "1024px", // ipad landscape -- next
  laptopL: "1440px", // important
  desktop: "2560px",
}

// my laptop view port 1280px

export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  tabletL: `(max-width: ${size.tabletL})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`,
}
