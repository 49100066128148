import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Link as GatsbyLink } from "gatsby"
import { Link } from "react-scroll"
import styled from "styled-components"
import { device } from "../../styles/BreakPoints"
import { ContainerLarge } from "../../styles/Global"
import useWindowSize from "../../hooks/useWindowSize"
import { navElements } from "../../site-data/navbar"

const NavStyle = styled.nav`
  background: #ffffff;
  height: 85px;
  display: flex;
  align-items: center;
  box-shadow: 0px 15px 50px #40155933;
  position: fixed;
  width: 100%;
  z-index: 101;
  top: 0;
  @media ${device.laptopL} {
    height: 56px;
  }
  @media ${device.mobileL} {
    bottom: 0;
    top: unset;
    height: 50px;
  }
`

const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const CompanyLogo = styled.div`
  width: 40px;
  height: 40px;
  border: 1px solid gray;
  border-radius: 50%;
  @media ${device.laptopL} {
    width: 35px;
    height: 35px;
  }
  @media ${device.mobileL} {
    width: 30px;
    height: 30px;
  }
`

const LinkContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  /* .active {
      border-bottom: 1px solid #320f67;
    } */
  a {
    position: relative;
  }
  a:not(:last-child) {
    margin-right: 70px;
    @media ${device.mobileL} {
      margin-right: 25px;
    }
    &::before {
      content: "";
      position: absolute;
      background: #ef5338;
      width: 2px;
      height: 100%;
      right: -35px;
      @media ${device.mobileL} {
        right: -12px;
      }
    }
  }
`

const LinkText = styled.nav`
  font-size: 21px;
  color: #320f67;
  cursor: pointer;
  @media ${device.laptopL} {
    font-size: 18px;
  }
  @media ${device.mobileL} {
    -webkit-tap-highlight-color: transparent;
    font-size: 14px;
  }
`

const BackBtn = styled.button`
  background: transparent linear-gradient(275deg, #481a72 0%, #57298b 100%);
  box-shadow: 0px 10px 24px #7e7e7e63;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  border-radius: 5px;
  border: none;
  outline: none;
  color: #ffffff;
  padding: 8px 12px;
  cursor: pointer;
  @media ${device.mobileL} {
    padding: 6px 12px;
    font-size: 16px;
  }
`

const Navbar = ({ back }) => {
  const [width] = useWindowSize()

  return (
    <NavStyle>
      <ContainerLarge>
        <NavContainer>
          <CompanyLogo>
            <GatsbyLink to="/">
              <StaticImage
                className="logo"
                src={"../../assets/logo.png"}
                alt="Contact SVG"
              />
            </GatsbyLink>
          </CompanyLogo>
          {back ? (
            <GatsbyLink to="/">
              <BackBtn>Go Back</BackBtn>
            </GatsbyLink>
          ) : (
            <LinkContainer>
              {navElements.map(({ title, to, id }) => (
                <Link
                  key={id}
                  to={to}
                  spy={true}
                  offset={width <= 600 ? 0 : -80}
                  duration={500}
                >
                  <LinkText>{title}</LinkText>
                </Link>
              ))}
            </LinkContainer>
          )}
        </NavContainer>
      </ContainerLarge>
    </NavStyle>
  )
}

export default Navbar
