import React from "react"
import styled, { ThemeProvider } from "styled-components"
import { device } from "../../styles/BreakPoints"
import { GlobalStyles } from "../../styles/Global"
import { theme } from "../../styles/Theme"
import Footer from "../Footer"
import Navbar from "../Navbar"

const LayoutContainer = styled.div`
  margin-top: 85px;
  @media ${device.laptopL} {
    margin-top: 50px;
  }
  @media ${device.mobileL} {
    margin-top: unset;
  }
`

function Layout({ children, nav, footer, back, mb }) {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {nav && <Navbar back={back} />}
      <LayoutContainer>{children}</LayoutContainer>
      {footer && <Footer mb={mb} />}
    </ThemeProvider>
  )
}

export default Layout
